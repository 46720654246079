import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SessionActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    onCancelSale,
  } = props;


  const classes = classNames(rootClassName || css.actionButtons, className);


  return showButtons ? (
    <div className={classes}>

      <div className={css.actionButtonWrapper}>
        <SecondaryButton
          onClick={onCancelSale}
        >
          <FormattedMessage id="TransactionPanel.cancelButton" />
        </SecondaryButton>        
      </div>
    </div>
  ) : null;
};

export default SessionActionButtonsMaybe;