import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { Modal, Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';


import css from './PasswordRecoveryForm.css';

class PasswordResetWithCodeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }


  render() {
    return (

      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            values,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);

          return (

            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <FieldTextInput
                className={css.email}
                type="code"
                //id={formId ? `${formId}.code` : 'code'}
                id="code"
                name="code"
                label="Code"
              />

              <FieldTextInput
                className={css.password}
                type="password"
                //id={formId ? `${formId}.newPassword` : 'newPassword'}
                id="newPassword"
                name="newPassword"
                label="New Password"
              />

              <div className={css.bottomWrapper}>
                <PrimaryButton>
                  <FormattedMessage id="PasswordRecoveryForm.setNewPassword"/>
                </PrimaryButton>
              </div>
            </Form>
          )
        }}
      >
      </FinalForm>


    )
  }

}

PasswordResetWithCodeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  recoveryError: null,
  passwordResetCognito: null,
  emailSent: false,
};

const { bool, string } = PropTypes;

PasswordResetWithCodeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,

  inProgress: bool,
  recoveryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};


  const PasswordResetWithCodeForm = compose(injectIntl)(PasswordResetWithCodeFormComponent);
PasswordResetWithCodeForm.displayName = 'PasswordResetWithCodeForm';

export default PasswordResetWithCodeForm;
