import { storableError } from '../../util/errors';
import AWS from 'aws-sdk';

// ================ Action types ================ //

export const CHANGE_PASSWORD_REQUEST = 'app/PasswordChangePage/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'app/PasswordChangePage/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/PasswordChangePage/CHANGE_PASSWORD_ERROR';

export const CHANGE_PASSWORD_CLEAR = 'app/PasswordChangePage/CHANGE_PASSWORD_CLEAR';

// ================ Reducer ================ //

const initialState = {
  changePasswordError: null,
  changePasswordInProgress: false,
  passwordChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordInProgress: true,
        changePasswordError: null,
        passwordChanged: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordInProgress: false, passwordChanged: true };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, changePasswordInProgress: false, changePasswordError: payload };

    case CHANGE_PASSWORD_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const changePasswordRequest = () => ({ type: CHANGE_PASSWORD_REQUEST });
export const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });
export const changePasswordError = error => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
  error: true,
});

export const changePasswordClear = () => ({ type: CHANGE_PASSWORD_CLEAR });

// ================ Thunks ================ //

const parseCookie = str => {
  const cookies = str.split(';')

  const cookie = cookies.find(element => element.search("access_token") > 0).split('=')
  
  return JSON.parse(decodeURIComponent(cookie[1].trim()))

}

export const changePassword = params => (dispatch, getState, sdk) => {
  //dispatch(changePasswordRequest());
  const { newPassword, currentPassword } = params;

  var cookie = parseCookie(document.cookie);

  window.alert(cookie['access_token']);

  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  })

  const cognito = new AWS.CognitoIdentityServiceProvider()
  var passwordParams = {
    AccessToken: cookie['access_token'], /* required */
    PreviousPassword: currentPassword, /* required */
    ProposedPassword: newPassword /* required */
  };
  const cognitoResetPasswordRequest = cognito.changePassword(passwordParams, null);
  cognitoResetPasswordRequest.
     on('error', function(error, response) {
       console.log("Error!");
       alert("There was an error reseting the password")
     }).
     on('complete', function(response) {
       console.log("Always!");
     }).
     send();
  
  return;


  /*
  return sdk.currentUser
    .changePassword({ newPassword, currentPassword })
    .then(() => dispatch(changePasswordSuccess()))
    .catch(e => {
      dispatch(changePasswordError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
    */
};
