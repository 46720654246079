import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionTestimonials,
  SectionLocations,
  SectionDescription,
  SectionLogin,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { TopbarSearchForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { parse } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import {isMobile} from 'react-device-detect';
//import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
//import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.css';
import { NamedLink } from '../../components';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    isAuthenticated,
  } = props;

  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const searchSubmit = values => {
    const keywords = values.keywords;
    const searchParams = {
      keywords,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  //const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const schemaImage = `${config.canonicalRootURL}`;

    if (isMobile) {
        return (
          <Page
          className={css.root}
          scrollingDisabled={scrollingDisabled}
          contentType="website"
          description={schemaDescription}
          title={schemaTitle}
          /*facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
          twitterImages={[
            { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
          ]}*/
          schema={{
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            description: schemaDescription,
            name: schemaTitle,
            //image: [schemaImage],
          }}
        >
          <LayoutWrapperTopbar>
            <a class="TopbarDesktop_logoLink__2nXAl NamedLink_active" href="/">
              <img class="TopbarDesktop_logo__1rCaf" src="/static/media/Polis_Logo.908e9c97.png" alt="Polis"/>
            </a>
            {/* <img class="TopbarDesktop_logo__1rCaf" src="/static/media/Polis_Logo.908e9c97.png" alt="Polis"/> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p class="LandingPage_section__J7HsI LandingPage_specSection__2KnUf">
              <div class="LandingPage_sectionContentFirstChild__2JmOe LandingPage_sectionContent__1dZF1">
                <div class="">
                  <div class="SectionDescription_title__10WiZ">
                  <span>Mobile browsers are not recommend for viewing our site, please use a desktop browser.</span>
                    </div>
                  <div class="SectionDescription_stepTitle__1nz59">
                    <span>
                      You can return to our homepage by <a style={{color:"red"}} href="/"> clicking here </a> if you wish to continue still.
                    </span>
                  </div>
                </div>
              </div>
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </Page>
      )
    } else {
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        /*facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}*/
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          //image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.heroContainer}>
              <SectionHero className={css.hero} history={history} location={location} />
            </div>
            <ul className={css.sections}>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionHowItWorks
                    currentUserListing={currentUserListing}
                    currentUserListingFetched={currentUserListingFetched}
                  />
                </div>
              </li>
              <li className={`${css.section} ${css.specSection} ${css.searchSection}`}>
                <div className={css.sectionContentFirstChild}>
                  <h2 className={css.title}>
                    <FormattedMessage id="SectionSearch.title" />
                  </h2>
                  <TopbarSearchForm
                    className={css.searchLink}
                    desktopInputRoot={css.topbarSearchWithLeftPadding}
                    onSubmit={searchSubmit}
                    initialValues={initialSearchFormValues}
                  />
                </div>
              </li>
              {/* <li className={css.section}>
                <div className={css.sectionContentFirstChild}>
                  <SectionLocations />
                </div>
              </li> */}
              <li className={`${css.section} ${css.specSection}`}>
                <div className={css.sectionContentFirstChild}>
                  <SectionDescription />
                </div>
              </li>
              <li className={css.section}>
                <div className={css.sectionContentFirstChild}>
                  <SectionTestimonials />
                </div>
              </li>
              {isAuthenticated ? null : (
                <li className={css.section}>
                  <div className={css.sectionContentFirstChild}>
                    <SectionLogin />
                  </div>
                </li>
              )}
            </ul>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  };
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  isAuthenticated: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
