import React from 'react';
import { string } from 'prop-types';
import { FieldSelect } from '..';

const FieldStopBookingInSelect = props => {
  // IANA database contains irrelevant time zones too.
  const timeIn = [...Array(72).keys()];

  return (
    <FieldSelect placeholder="12h..." {...props}>
      {/*<option key={12} value={"12"}>
        12h
  </option>*/}
      {timeIn.map(time => (
        <option key={time + 12} value={time + 12}>
          {`${time + 12}h`}
        </option>
      ))}
    </FieldSelect>
  );
};

FieldStopBookingInSelect.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

FieldStopBookingInSelect.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  name: string.isRequired,
};

export default FieldStopBookingInSelect;
