import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Button,
  NamedLink,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';

import css from './EmailVerificationPage.css';

import AWS from 'aws-sdk';

export class EmailVerificationPage extends Component {

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search)
    
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  })

    this.state = {
      scrollingDisabled: true,
      code: query.get('code'),
      username: query.get('username'),
      verified: false,
      verificationError: null,
    };

  };



  resendConfirmationCode() {
    const cognito =  new AWS.CognitoIdentityServiceProvider()
    var params = {
      ClientId: process.env.REACT_APP_POLIS_CLIENT_ID, /* required */
      Username: this.state.username, /* required */
    };
    cognito.resendConfirmationCode(params, (err, data) => {
      if (err) console.log(err, err.stack); // an error occurred
      else     console.log(data);           // successful response
    });
  }


  componentWillMount() {
    const cognito =  new AWS.CognitoIdentityServiceProvider()
    var params = {
      ClientId: process.env.REACT_APP_POLIS_CLIENT_ID, /* required */
      ConfirmationCode: this.state.code, /* required */
      Username: this.state.username, /* required */

    };
    cognito.confirmSignUp(params, (err, data) => {
        if (err) {
          console.log(err)
          console.log(err.stack)
          //console.log(err, err.stack); // an error occurred
          this.setState({
            verificationError: err
          })
        } else {
          console.log(data);           // successful response
          console.log("Correct Verification")
          this.setState({
            verified: true
          })          
        };
    });
  }


  renderContent() {
    if ( this.state.verificationError != null ) {
      console.log(this.state.verificationError);
      return(
        <div>
          <h2>Error Verifying Your E-mail Address </h2>
          <Button onClick={this.resendConfirmationCode}> Resend Confirmation Code</Button>
        </div>
      )
    }
    console.log("renderContent")
    console.log(this.state.verified)
    if (this.state.verified) {
      return(
        <div>
          <h2>E-mail Address Successfully Verified!</h2>

          <NamedLink name="LoginPage" className={css.submitButton}> Login To Your Polis Account</NamedLink>

        </div>
      );
    }

    
    return (<FormattedMessage id="EmailVerificationPage.loadingUserInformation" />);
  }

  render() {

    return (
      <Page title="Email Verification Page"  referrer="origin">
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <div className={css.content}>
                  {this.renderContent()}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  };
}



export default EmailVerificationPage;
