// Normalize phone number

/**
 * Format a phone number: this is just an override for default formatting.
 */
export const format = value => (!value ? '' : value);

/**
 * Parser that strips non-digit characters away from a phone number
 * string unless they are dashes in correct places.
 *
 * Returns the given US phone number in format: 202-555-0102
 */
export const parse = value => {
  if (!value) {
    return value;
  }

  //const phoneNumber =
  //  typeof value === 'string' && value.indexOf('+1') >= 0 ? value.substring(2) : value;

  const phoneNumber = value;

  return phoneNumber;
  //return `${phoneNumber.slice(0,2)}-${phoneNumber.slice(2,5)}`
  /*
  if (phoneNumber.length <= 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}`;
  } else {
    return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)}-${phoneNumber.slice(7, 11)}`;
  }*/
};
