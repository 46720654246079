import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionTestimonials.css';

const SectionTestimonials = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionTestimonials.titleLineOne" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          {/* <div className={css.avatar}></div> */}
          <p>
            <FormattedMessage id="SectionTestimonials.part1Text" />
          </p>          
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionTestimonials.part1Title" />
          </h3>
        </div>

        <div className={css.step}>
          {/* <div className={css.avatar}></div> */}
          <p>
            <FormattedMessage id="SectionTestimonials.part2Text" />
          </p>          
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionTestimonials.part2Title" />
          </h3>
        </div>

        <div className={css.step}>
          {/* <div className={css.avatar}></div> */}
          <p>
            <FormattedMessage id="SectionTestimonials.part3Text" />
          </p>          
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionTestimonials.part3Title" />
          </h3>
        </div>
      </div>
    </div>
  );
};

SectionTestimonials.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionTestimonials.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionTestimonials;
