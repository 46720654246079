import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';

import css from './ListingPage.css';

const SectionHeading = props => {
  const { richTitle, showContactUser, onContactUser } = props;

  // const university = getUniversityInfo(universityOptions, listingUniversity);
  // const showUniversity = university && !university.hideFromListingInfo;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {/* {showUniversity ? <span>{university.label}</span> : null} */}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {/* {showUniversity ? <span className={css.separator}>•</span> : null} */}
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
