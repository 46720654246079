/**
 * This is the main entrypoint file for the application.
 *
 * When loaded in the client side, the application is rendered in the
 * #root element.
 *
 * When the bundle created from this file is imported in the server
 * side, the exported `renderApp` function can be used for server side
 * rendering.
 *
 * Note that this file is required for the build process.
 */

// React 16 depends on the collection types Map and Set, as well as requestAnimationFrame.
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { createInstance, types as sdkTypes } from './util/sdkLoader';
import { ClientApp, renderApp } from './app';
import configureStore from './store';
import { matchPathname } from './util/routes';
import * as sample from './util/sample';
import * as apiUtils from './util/api';
import config from './config';
import { authInfo } from './ducks/Auth.duck';
import { fetchCurrentUser } from './ducks/user.duck';
import routeConfiguration from './routeConfiguration';
import * as log from './util/log';
import { LoggingAnalyticsHandler, GoogleAnalyticsHandler, MatomoAnalyticsHandler } from './analytics/handlers';

import './marketplaceIndex.css';

const render = (store, shouldHydrate) => {
  // If the server already loaded the auth information, render the app
  // immediately. Otherwise wait for the flag to be loaded and render
  // when auth information is present.
  const authInfoLoaded = store.getState().Auth.authInfoLoaded;
  const info = authInfoLoaded ? Promise.resolve({}) : store.dispatch(authInfo());
  info
    .then(() => {
      store.dispatch(fetchCurrentUser());
      if (shouldHydrate) {
        ReactDOM.hydrate(<ClientApp store={store} />, document.getElementById('root'));
      } else {
        ReactDOM.render(<ClientApp store={store} />, document.getElementById('root'));
      }
    })
    .catch(e => {
      log.error(e, 'browser-side-render-failed');
    });
};

const setupAnalyticsHandlers = () => {
  let handlers = [];

  // Log analytics page views and events in dev mode
  if (config.dev) {
    handlers.push(new LoggingAnalyticsHandler());
  }

  /* Add Google Analytics handler if tracker ID is found. In the current version of Sharetribe
	it needs to be a Universal Analytics identifier (UA-****) not a G4 (G-****).
	TODO: Upgrade to G4
  Development: UA-199119879-1
  Production: UA-199119879-2
   */
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    handlers.push(new GoogleAnalyticsHandler(window.ga));
  }

  // Temporarily remove Matomo as we are not paying for it.
  // //Matomo Analytics
  //   var _paq = window._paq = window._paq || [];
  // /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  // _paq.push(['trackPageView']);
  // _paq.push(['enableLinkTracking']);
  // (function() {
  //   var u="https://polis.matomo.cloud/";
  //   _paq.push(['setTrackerUrl', u+'matomo.php']);
  //   _paq.push(['setSiteId', '1']);
  //   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //   g.type='text/javascript'; g.async=true; g.src='//cdn.matomo.cloud/polis.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  // })();

  return handlers;
};


/*
const checkPassword = () => {
var password = prompt("Password Please");
//Change to your own Password
if(password !=='Cambridge2021'){
document.body.innerHTML = '';
document.body.innerHTML = 'Password Failed! Reload to Renter Password';
}
}
checkPassword();
*/

// If we're in a browser already, render the client application.
if (typeof window !== 'undefined') {
  // set up logger with Sentry DSN client key and environment
  log.setup();

  const baseUrl = config.sdk.baseUrl ? { baseUrl: config.sdk.baseUrl } : {};

  // eslint-disable-next-line no-underscore-dangle
  const preloadedState = window.__PRELOADED_STATE__ || '{}';
  const initialState = JSON.parse(preloadedState, sdkTypes.reviver);
  const sdk = createInstance({
    transitVerbose: config.sdk.transitVerbose,
    clientId: config.sdk.clientId,
    secure: config.usingSSL,
    typeHandlers: apiUtils.typeHandlers,
    ...baseUrl,
  });
  const analyticsHandlers = setupAnalyticsHandlers();
  const store = configureStore(initialState, sdk, analyticsHandlers);

  require('./util/polyfills');
  render(store, !! window.__PRELOADED_STATE__);

  if (config.dev) {
    // Expose stuff for the browser REPL
    window.app = {
      config,
      sdk,
      sdkTypes,
      store,
      sample,
      routeConfiguration: routeConfiguration(),
    };
  }
}

// Show warning if CSP is not enabled
const CSP = process.env.REACT_APP_CSP;
const cspEnabled = CSP === 'block' || CSP === 'report';

if (CSP === 'report' && process.env.REACT_APP_ENV === 'production') {
  console.warn(
    'Your production environment should use CSP with "block" mode. Read more from: https://www.sharetribe.com/docs/ftw-security/how-to-set-up-csp-for-ftw/'
  );
} else if (!cspEnabled) {
  console.warn(
    "CSP is currently not enabled! You should add an environment variable REACT_APP_CSP with the value 'report' or 'block'. Read more from: https://www.sharetribe.com/docs/ftw-security/how-to-set-up-csp-for-ftw/"
  );
}

// Export the function for server side rendering.
export default renderApp;

// exporting matchPathname and configureStore for server side rendering.
// matchPathname helps to figure out which route is called and if it has preloading needs
// configureStore is used for creating initial store state for Redux after preloading
export { matchPathname, configureStore, routeConfiguration, config };
