import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

// import { NamedLink } from '../../components';

import css from './SectionLocations.css';

// class LocationImage extends Component {
//   render() {
//     const { alt, ...rest } = this.props;
//     return <img alt={alt} {...rest} />;
//   }
// }

// const locationLink = (name, image, searchQuery) => {
//   const nameText = <span className={css.locationName}>{name}</span>;
//   return (
//     <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
//       <div className={css.imageWrapper}>
//         <img src={image} alt={name} className={css.locationImage} />
//       </div>
//       <div className={css.linkText}>
//         <FormattedMessage
//           id="SectionLocations.listingsInLocation"
//           values={{ location: nameText }}
//         />
//       </div>
//     </NamedLink>
//   );
// };

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.contentWrapper}>
        {/* <div className={css.locations}>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionLocations.title1" />
          </h3>
          <div className={css.questions}>
            <h4 className={css.questionTitle}>
              <FormattedMessage id="SectionLocations.question1title" />
            </h4>
            <h4 className={css.questionTitle}>
              <FormattedMessage id="SectionLocations.question2title" />
            </h4>
            <h4 className={css.questionTitle}>
              <FormattedMessage id="SectionLocations.question3title" />
            </h4>
          </div>
        </div> */}
        <div className={css.schoolsLocations}>
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionLocations.title2" />
          </h3>
          <div className={css.schools}>
            <div className={css.uniTitle}>Harvard University</div>
            <div className={css.uniTitle}>University of Cambridge</div>
            <div className={css.uniTitle}>University of Pennsylvania</div>
            <div className={css.uniTitle}>University of Oxford</div>
            {/* {locationLink(
              'Harvard University',
              harvardImage,
              '?address=New%20York%20City%2C%20New%20York%2C%20USA&bounds=40.917576401307%2C-73.7008392055224%2C40.477399%2C-74.2590879797556'
            )}
            {locationLink(
              'University of Pennsylvania',
              pensImage,
              '?address=Los%20Angeles%2C%20California%2C%20USA&bounds=34.161440999758%2C-118.121305008073%2C33.9018913203336%2C-118.521456965901'
            )}
            {locationLink(
              'Cambridge University',
              cambridgeImage,
              '?address=San%20Francisco%2C%20California%2C%20USA&bounds=37.8324430069081%2C-122.354995082683%2C37.6044780500533%2C-122.517910874663'
            )}
            {locationLink(
              'Oxford University',
              oxfordImage,
              '?address=San%20Francisco%2C%20California%2C%20USA&bounds=37.8324430069081%2C-122.354995082683%2C37.6044780500533%2C-122.517910874663'
            )} */}
          </div>
          <div className={css.schools}>
            <div className={css.uniTitle}>MIT</div>
            <div className={css.uniTitle}>Stanford University</div>
            <div className={css.uniTitle}>Princeton University</div>
            <div className={css.uniTitle}>University of Michigan</div>
          </div>
          <div className={css.schools}>
            <div className={css.uniTitle}>U.C. Berkeley</div>
            <div className={css.uniTitle}>University of Chicago</div>
            <div className={css.uniTitle}>Yale University</div>
            <div className={css.uniTitle}>Duke University</div>
          </div>                    
        </div>
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
