import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './SectionHowItWorks.css';

import IconBook from './images/check-mark.svg';
import IconSearch from './images/magnifier.svg';
import IconLogin from './images/log-out.svg';
import IconLearn from './images/school.svg';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        <br />
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <div className={css.backIcon}>
            <img className={css.icon} src={IconLogin} alt="loginIcon" />
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.backIcon}>
            <img className={css.icon} src={IconSearch} alt="searchIcon" />
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.backIcon}>
            <img className={css.icon} src={IconBook} alt="bookIcon" />
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>

        <div className={css.step}>
          <div className={css.backIcon}>
            <img className={css.icon} src={IconLearn} alt="learhIcon" />
          </div>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part4Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionHowItWorks.part4Text" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
