import React from 'react';
import {FormattedMessage} from '../../util/reactIntl';
import {PropertyGroup} from '../../components';

import css from './ListingPage.css';
import {richText} from "../../util/richText";

const getDegreeValue = (degree, degreeOptions) => {
  const {label} = degreeOptions.find(op => op.key === degree) || {label: ''};
  return label;
};

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionFeatures = props => {
  const {
    categoryOptions,
    languagesOptions,
    disciplinaryOptions,
    publicData,
    pronounOptions,
    titleOptions,
    degreeOptions
  } = props;
  if (!publicData) {
    return null;
  }
  const educationArray = publicData && publicData.uniDegree ? publicData.uniDegree : [];
  const currentPosition = publicData.currentPosition || null;
  const currentOrg = publicData.currentOrg || null;

  const preferedPronoun = publicData && publicData.pronoun ? publicData.pronoun : '';
  const seletedPronoun = pronounOptions.find(s => preferedPronoun === s.key);

  const title = publicData && publicData.titleOcc ? publicData.titleOcc : '';
  const seletedTitle = titleOptions.find(s => title === s.key);

  //TODO: Made one fuction
  const selectedCategoryOptions = publicData && publicData.category ? publicData.category : [];
  const selectedConfigOptions = categoryOptions.filter(o =>
    selectedCategoryOptions.find(s => s === o.key)
  );

  const selectedLangugeOptions = publicData && publicData.languages ? publicData.languages : [];
  const selectedLConfigOptions = languagesOptions.filter(o =>
    selectedLangugeOptions.find(s => s === o.key)
  );

  const selectedDisciplinaryOptions =
    publicData && publicData.disciplinary ? publicData.disciplinary : [];
  const selectedDConfigOptions = disciplinaryOptions.filter(o =>
    selectedDisciplinaryOptions.find(s => s === o.key)
  );

  return (
    <>

      <div className={css.row}>
        {/* Current Position */}
        {currentPosition ? (
          <div className={css.sectionFeaturesColumn}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.currentPositionTitle"/>
            </h2>
            <p>{currentPosition}</p>
          </div>
        ) : null}
        {/* Current Organization */}
        {currentOrg ? (
          <div className={css.sectionFeaturesColumn}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.currentOrgTitle"/>
            </h2>
            <p>{currentOrg}</p>
          </div>
        ) : null}
      </div>
      <div className={css.row}>
        {title ? (
          <div className={css.sectionFeaturesColumn}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.title"/>
            </h2>
            <p className={css.description}>
              {richText(seletedTitle.label, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
          </div>
        ) : null}
        {preferedPronoun ? (
          <div className={css.sectionFeaturesColumn}>
            <h2 className={css.descriptionTitle}>
              <FormattedMessage id="ListingPage.pronounTitle"/>
            </h2>
            <p className={css.description}>
              {richText(seletedPronoun.label, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
          </div>
        ) : null}
      </div>
      <div className={css.row}>
        {/* Education */}
        <div className={css.sectionFeaturesColumn}>
          <h2 className={css.descriptionTitle}>
            <FormattedMessage id="ListingPage.educationTitle"/>
          </h2>
          <div>
            {educationArray.map(
              item =>
                item.university && (
                  <div className={css.educationWrapper}>
                    <p>{item.university}</p>
                    <span className={css.separator}>•</span>
                    {item.degree && <p>{getDegreeValue(item.degree, degreeOptions)}</p>}
                  </div>
                )
            )}
          </div>
        </div>
        {/*Office hours types*/}
        <div className={css.sectionFeaturesColumn}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id="ListingPage.featuresTitle"/>
          </h2>
          <PropertyGroup
            id="ListingPage.yogaStyles"
            options={selectedConfigOptions}
            selectedOptions={selectedCategoryOptions}
            twoColumns={selectedConfigOptions.length > 5}
          />
        </div>


      <div className={css.row}>

        {/*Disciplinary Background*/}
        <div className={css.sectionFeaturesColumn}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id="ListingPage.disciplinaryTitle"/>
          </h2>
          <PropertyGroup
            id="ListingPage.yogaStyles"
            options={selectedDConfigOptions}
            selectedOptions={selectedDisciplinaryOptions}
            twoColumns={selectedDConfigOptions.length > 5}
          />
        </div>
        {/*Spoken Languages*/}
        <div className={css.sectionFeaturesColumn}>
          <h2 className={css.featuresTitle}>
            <FormattedMessage id="ListingPage.languagesTitle"/>
          </h2>
          <PropertyGroup
            id="ListingPage.yogaStyles"
            options={selectedLConfigOptions}
            selectedOptions={selectedLangugeOptions}
            twoColumns={selectedLConfigOptions.length > 5}
          />
        </div>
      </div>

      </div>
    </>
  );
};

export default SectionFeatures;
