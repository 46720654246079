import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        titleOptions,
        genderOptions,
        pronounOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const currentPositionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.currentPositionPlaceholder',
      });
      const currentPositionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.currentPosition',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const currentOrgPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.currentOrgPlaceholder',
      });
      const currentOrgMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.currentOrg',
      });

      const titleLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleLabel',
      });

      const genderLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.genderLabel',
      });

      const pronounLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.pronounLabel',
      });

      const linkedinLabel = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.linkedin',
      });

      const websiteLabel = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.website',
      });
      const linkedinPlaceholder = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.linkedinPlaceholder',
      });

      const websitePlaceholder = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.websitePlaceholder',
      });

      const requiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.requiredMessage',
      });
      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.filterContainer}>
            <div className={css.column}>
              <FieldTextInput
                id="currentPosition"
                name="currentPosition"
                className={css.title}
                type="text"
                label={currentPositionMessage}
                placeholder={currentPositionPlaceholderMessage}
                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                autoFocus
              />

              <FieldSelect
                className={css.certificate}
                name="titleOcc"
                id="titleOcc"
                label={titleLabel}
                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
              >
                {titleOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
             {/* <FieldSelect
                className={css.certificate}
                name="gender"
                id="gender"
                label={genderLabel}
                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
              >
                {genderOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
                </FieldSelect> */}
            </div>
            <div className={css.column}>
              <FieldTextInput
                id="currentOrg"
                name="currentOrg"
                className={css.title}
                type="text"
                label={currentOrgMessage}
                placeholder={currentOrgPlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={maxLength60Message}
                autoFocus
              />

              <FieldSelect
                className={css.certificate}
                name="pronoun"
                id="pronoun"
                label={pronounLabel}
                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
              >
                {pronounOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
            </div>
          </div>

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            rows="4"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
          />

          <div className={css.secondSection}>
            <FieldTextInput
              id="linkedin"
              name="linkedin"
              className={css.link}
              type="text"
              label={linkedinLabel}
              placeholder={linkedinPlaceholder}
              autoFocus
            />

            <p>


            </p>

            <FieldTextInput
              id="website"
              name="website"
              className={css.link}
              type="text"
              label={websiteLabel}
              placeholder={websitePlaceholder}
              autoFocus
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  universityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  titleOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  degreeOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
