import React, {useState} from 'react';

import css from './TransactionPanel.css';
import Button from '../Button/Button';
import { ensureUser } from '../../util/data';
import { getCognitoUserID } from '../../OIKOS/OIKOS';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const MeetingLinkSeaction = props => {
  const [loaded, setLoaded] = useState(false);
  const [cognitoLearnerID, setCognitoLearnerID] = useState("");
  const [cognitoEducatorID, setCognitoEducatorID] = useState("");
  const [cognitoCurrentUserID, setCognitoCurrentUserID] = useState("");
  const [roomName, setRoomName] = useState("");
  const { currentTransaction, currentUser } = props;

  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);


  if (!loaded) {
      getCognitoUserID(currentCustomer.id.uuid).then((res) => {
        // cognitoLearnerID = res;
        setCognitoLearnerID(res);
        getCognitoUserID(currentProvider.id.uuid).then((res) => {
          setCognitoEducatorID(res);
          setRoomName(cognitoEducatorID + "-" + cognitoLearnerID)
          getCognitoUserID(currentUser.id.uuid).then((res) => {
            setCognitoCurrentUserID(res);
            setLoaded(true);
          });
        });
      });

  }


  //
  // const room = custPart + provPart;
  // const userID = currentUser.id.uuid

  return (
    <>
    {loaded && <Button className={css.meetButton}>
      <a
        className={css.meetLink}
        rel="noopener noreferrer"
        target="_blank"
        href={`https://stoa.polis.ac/room/${roomName}/${cognitoCurrentUserID}`}
      >
        Link To Your Meeting
      </a>
    </Button>
    }
    </>
  );
};

export default MeetingLinkSeaction;
