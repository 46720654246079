import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  NamedLink,
  IconKeys,
  IconKeysSuccess,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer, Modal,
} from '../../components';
import { PasswordResetForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { resetPassword } from './PasswordResetPage.duck';
import css from './PasswordResetPage.css';

import AWS from 'aws-sdk';

const parseUrlParams = location => {
  const params = parse(location.search);
  const { t: token, e: email } = params;
  return { token, email };
};

export class PasswordResetPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPasswordSubmitted: false,
      errorMessage: '',
      isErrorOpen: false,
      isSuccessOpen: false
    };
  }



  render() {
    const {
      intl,
      scrollingDisabled,
      location,
      resetPasswordInProgress,
      resetPasswordError,
    } = this.props;


    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    })
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

    const changePasswordCognito = (oldPassword, newPassword) => {

      const local_storage =window.localStorage;
      const accessToken = local_storage.getItem("AccessToken");

      const params = {
        AccessToken: accessToken, /* required */
        PreviousPassword: oldPassword, /* required */
        ProposedPassword: newPassword /* required */
      };

      cognitoidentityserviceprovider.changePassword(params, function(err, data) {
        if (err) {
          this.setState({
            errorMessage: err.toString(),
            isErrorOpen: true,
          })
          console.log(err)
        } else {
          this.setState({
            newPasswordSubmitted: true,
            isSuccessOpen: true,
          })
          console.log(data);
        }           // successful response
      });

    }


    const title = intl.formatMessage({
      id: 'PasswordResetPage.title',
    });

    const { token, email } = parseUrlParams(location);
    const paramsValid = !!(token && email);

    const handleSubmit = values => {
      const { oldPassword, newPassword } = values;
      this.setState({ newPasswordSubmitted: false });
      changePasswordCognito(oldPassword, newPassword)
    };

    const recoveryLink = (
      <NamedLink name="PasswordRecoveryPage">
        <FormattedMessage id="PasswordResetPage.recoveryLinkText" />
      </NamedLink>
    );
    const paramsErrorContent = (
      <div className={css.content}>
        <p>
          <FormattedMessage id="PasswordResetPage.invalidUrlParams" values={{ recoveryLink }} />
        </p>
      </div>
    );

    const resetFormContent = (
      <div className={css.content}>
        <IconKeys className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="PasswordResetPage.mainHeading" />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="PasswordResetPage.helpText" />
        </p>
        {resetPasswordError ? (
          <p className={css.error}>
            <FormattedMessage id="PasswordResetPage.resetFailed" />
          </p>
        ) : null}
        <PasswordResetForm
          className={css.form}
          onSubmit={handleSubmit}
          inProgress={resetPasswordInProgress}
        />
      </div>
    );

    const resetDoneContent = (
      <div className={css.content}>
        <IconKeysSuccess className={css.modalIcon} />
        <h1 className={css.modalTitle}>
          <FormattedMessage id="PasswordResetPage.passwordChangedHeading" />
        </h1>
        <p className={css.modalMessage}>
          <FormattedMessage id="PasswordResetPage.passwordChangedHelpText" />
        </p>
        <NamedLink name="LoginPage" className={css.submitButton}>
          <FormattedMessage id="PasswordResetPage.loginButtonText" />
        </NamedLink>
      </div>
    );

    let content;

    if (!paramsValid) {
      content = paramsErrorContent;
    } else if (!resetPasswordError && this.state.newPasswordSubmitted) {
      content = resetDoneContent;
    } else {
      content = resetFormContent;
    }

    const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
      // We are just checking the value for now
      //console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
    };

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled} referrer="origin">
        {/** Success Modal */}
        <Modal
          {...this.props}
          isOpen={this.state.isSuccessOpen}
          onClose={() => {
            this.setState({isSuccessOpen: false})
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div style={{ margin: '1rem' }}>Password Reset Successful</div>
        </Modal>

        {/** Error Modal */}
        <Modal
          {...this.props}
          isOpen={this.state.isErrorOpen}
          onClose={() => {
            this.setState({isErrorOpen: false})
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div style={{ margin: '1rem' }}>Error: {this.state.errorMessage}</div>
        </Modal>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>{content}</div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

PasswordResetPageComponent.defaultProps = {
  resetPasswordError: null,
};

const { bool, func, shape, string } = PropTypes;

PasswordResetPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  resetPasswordInProgress: bool.isRequired,
  resetPasswordError: propTypes.error,
  onSubmitPassword: func.isRequired,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { resetPasswordInProgress, resetPasswordError } = state.PasswordResetPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    resetPasswordInProgress,
    resetPasswordError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitPassword: (email, token, password) => dispatch(resetPassword(email, token, password)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PasswordResetPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PasswordResetPageComponent);

export default PasswordResetPage;
