import React from 'react';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.css';

const SectionImages = props => {
  const { listing, isOwnListing, editParams, handleViewPhotosClick } = props;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
        </div>
      </div>
    </div>
  );
};

export default SectionImages;
