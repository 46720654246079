import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { required, composeValidators, requiredFieldArrayCheckbox } from '../../util/validators';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  Form,
  FieldTextInput,
  InlineTextButton,
  IconClose,
  FieldSelect,
} from '../../components';

import css from './EditListingFeaturesForm.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        intl,
        handleSubmit,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        categoryOptions,
        degreeOptions,
        languageOptions,
        disciplinaryOptions,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const requiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.requiredMessage',
      });

      const subCatogorydescriptionMessage = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.subCategoryPlaceholder',
      });

      const categoryLabel = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.categoryLabel',
      });
      const languageLabel = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.languageLabel',
      });
      const educationLabel = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.educationLabel',
      });
      const keywordsLabel = intl.formatMessage({
        id: 'ListingPage.keywordsTitle',
      });
      const disciplinaryLabel = intl.formatMessage({
        id: 'EditListingProfessionalSkillsForm.disciplinaryLabel',
      });

      const universityMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.university',
      });
      const universityPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.universityPlaceholder',
      });
      const degreeLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.degreeLabel',
      });

      const additionalInfoMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.additionalInfo',
      });
      const additionalInfoPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.additionalInfoPlaceholder',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.firstSection}>
            <div class={css.column}>
              <FieldCheckboxGroup
                className={css.features}
                id="category"
                name="category"
                label={categoryLabel}
                options={categoryOptions}
                validate={submitInProgress ? null : composeValidators(requiredFieldArrayCheckbox(requiredMessage))}
              />
              <FieldCheckboxGroup
                className={css.features}
                id="disciplinary"
                name="disciplinary"
                label={disciplinaryLabel}
                options={disciplinaryOptions}
                validate={submitInProgress ? null : composeValidators(requiredFieldArrayCheckbox(requiredMessage))}
              />
            </div>

            <div class={css.column}>
              <legend>{educationLabel}</legend>
              <FieldArray name="uniDegree">
                {({ fields }) => {
                  return (
                    <div>
                      {fields.map((name, index) => {
                        if (index === 0) {
                          return (
                            <>
                              <FieldTextInput
                                id={`${name}.university`}
                                name={`${name}.university`}
                                className={css.title}
                                type="text"
                                label={universityMessage}
                                placeholder={universityPlaceholderMessage}
                                autoFocus
                                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                              />

                              <FieldSelect
                                className={css.certificate}
                                id={`${name}.degree`}
                                name={`${name}.degree`}
                                label={degreeLabel}
                                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                              >
                                {degreeOptions.map(c => (
                                  <option key={c.key} value={c.key}>
                                    {c.label}
                                  </option>
                                ))}
                              </FieldSelect>
                            </>
                          );
                        }
                        return null;
                      })}

                      {fields.map((name, index) => {
                        if (index !== 0) {
                          return (
                            <div key={name}>
                              <div className={css.uniBlockWrapper}>
                                <div className={css.uniBlock}>
                                  <FieldTextInput
                                    id={`${name}.university`}
                                    name={`${name}.university`}
                                    className={css.title}
                                    type="text"
                                    label={universityMessage}
                                    placeholder={universityPlaceholderMessage}
                                    autoFocus
                                    validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                                  />

                                  <FieldSelect
                                    className={css.certificate}
                                    name={`${name}.degree`}
                                    id={`${name}.degree`}
                                    label={degreeLabel}
                                    validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                                  >
                                    {degreeOptions.map(c => (
                                      <option key={c.key} value={c.key}>
                                        {c.label}
                                      </option>
                                    ))}
                                  </FieldSelect>
                                </div>
                                <div
                                  className={css.fieldArrayRemove}
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <IconClose rootClassName={css.closeIcon} />
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <InlineTextButton
                        type="button"
                        className={css.buttonAddNew}
                        onClick={() => fields.push({ degree: null, university: null })}
                      >
                        +<FormattedMessage id={"General.addOneMore"}/>
                      </InlineTextButton>
                    </div>
                  );
                }}
              </FieldArray>
            </div>
          </div>

          <div className={css.firstSection}>
            <div class={css.column}>
              <div className={css.lanTitle}>{languageLabel}</div>
              <FieldArray name="language">
                {({ fields }) => {
                  return (
                    <div className={css.lanContainer}>
                      {fields.map((name, index) => {
                        if (index === 0) {
                          return (
                            <div key={name}>
                              <div className={css.uniBlockWrapper}>
                                <div className={css.lanBlock}>
                                  <FieldSelect
                                    className={css.certificate}
                                    name={`${name}.language`}
                                    id={`${name}.language`}
                                    validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                                    // label={languageLabel}
                                  >
                                    {languageOptions.map(c => (
                                      <option key={c.key} value={c.key}>
                                        {c.label}
                                      </option>
                                    ))}
                                  </FieldSelect>
                                </div>
                              </div>
                            </div>
                          );
                        }

                        if (index !== 0) {
                          return (
                            <div key={name}>
                              <div className={css.uniBlockWrapper}>
                                <div className={css.lanBlock}>
                                  <FieldSelect
                                    className={css.certificate}
                                    name={`${name}.language`}
                                    id={`${name}.language`}
                                    // label={languageLabel}
                                    validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
                                  >
                                    {languageOptions.map(c => (
                                      <option key={c.key} value={c.key}>
                                        {c.label}
                                      </option>
                                    ))}
                                  </FieldSelect>
                                </div>
                                <div
                                  className={css.lanFieldArrayRemove}
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <IconClose rootClassName={css.closeIcon} />
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <InlineTextButton
                        type="button"
                        className={css.buttonAddNew}
                        onClick={() => fields.push('')}
                      >
                        +<FormattedMessage id={"General.addLanguage"} />
                      </InlineTextButton>
                    </div>
                  );
                }}
              </FieldArray>
            </div>
          </div>

          <FieldTextInput
                id="keywords"
                name="keywords"
                className={css.description}
                type="textarea"
                label={keywordsLabel}
                placeholder={subCatogorydescriptionMessage}
                rows="3"
                validate={submitInProgress ? null : composeValidators(required(requiredMessage))}
          />

          <br/>
          <p>
            </p>
          <br/>

          <FieldTextInput
            id="additionalInfo"
            name="additionalInfo"
            className={css.description}
            type="textarea"
            label={additionalInfoMessage}
            rows="1"
            placeholder={additionalInfoPlaceholderMessage}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
