import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  InlineTextButton,
  IconKeys,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer, Modal,
} from '../../components';
import { PasswordRecoveryForm } from '../../forms';
import { TopbarContainer } from '../../containers';
//import Amplify, { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

import {
  recoverPassword,
  retypePasswordRecoveryEmail,
  clearPasswordRecoveryError,
} from './PasswordRecoveryPage.duck';
import css from './PasswordRecoveryPage.css';
import PaswordResetWithCodeForm from "../../forms/PasswordRecoveryForm/PasswordResetWithCodeForm";
import {createResourceLocatorString} from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";


export const PasswordRecoveryPageComponent = props => {
  const {
    scrollingDisabled,
    initialEmail,
    submittedEmail,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
    onChange,
    onRetypeEmail,
    intl,
  } = props;

  const [emailSent, setEmailSent] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState("")
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")

  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
  })
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

  const recoverPasswordCognito = email => {


    const params = {
      ClientId: process.env.REACT_APP_POLIS_CLIENT_ID, /* required */
      Username: email, /* required */
    };
    cognitoidentityserviceprovider.forgotPassword(params, function(err, data) {
      if (err) {setErrorMessage(err.toString()); setErrorOpen(true); console.log(err)}// an error occurred
      else     console.log(data);           // successful response
    });

    // Amplify.configure({
    //   Auth: {
    //     region: process.env.REACT_APP_AWS_REGION,
    //     userPoolId: process.env.REACT_APP_POLIS_USER_POOL_ID,
    //     userPoolWebClientId: process.env.REACT_APP_POLIS_CLIENT_ID
    //   }
    // })
    //
    // Auth.forgotPassword(email)
    //   .then(data => console.log(data))
    //   .catch(err => {setErrorMessage(err.toString()); setErrorOpen(true); console.log(err)});
    // console.log(email)

    setRecoveryEmail(email)
    setEmailSent(true)
  }

  const resetPasswordWithCodeCognito = (code, newPassword) => {
    const params = {
      ClientId: process.env.REACT_APP_POLIS_CLIENT_ID, /* required */
      ConfirmationCode: code, /* required */
      Password: newPassword, /* required */
      Username: recoveryEmail, /* required */
    };
    cognitoidentityserviceprovider.confirmForgotPassword(params, function(err, data) {
      if (err) {
        //console.log(err);
        setErrorMessage(err.toString());
        setErrorOpen(true);
      } else {
        setSuccessOpen(true);
      }          // successful response
    });
    // Amplify.configure({
    //   Auth: {
    //     region: process.env.REACT_APP_AWS_REGION,
    //     userPoolId: process.env.REACT_APP_POLIS_USER_POOL_ID,
    //     userPoolWebClientId: process.env.REACT_APP_POLIS_CLIENT_ID
    //   }
    // })
    //
    //
    // Auth.forgotPasswordSubmit(recoveryEmail, code, newPassword)
    //   .then(data => setSuccessOpen(true))
    //   .catch(err => {setErrorMessage(err.toString()); setErrorOpen(true); console.log(err)});
  }

  const title = intl.formatMessage({
    id: 'PasswordRecoveryPage.title',
  });

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.helperLink} onClick={() => recoverPasswordCognito(submittedEmail)}>
      <FormattedMessage id="PasswordRecoveryPage.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <InlineTextButton rootClassName={css.helperLink} onClick={onRetypeEmail}>
      <FormattedMessage id="PasswordRecoveryPage.fixEmailLinkText" />
    </InlineTextButton>
  );

  const redirectTo = createResourceLocatorString('LoginPage', routeConfiguration(), {}, {});
  const redirect = success ? (<Redirect to={redirectTo}/>) : (<></>);

  const message = emailSent ?
    (
      <>
        <p className={css.modalMessage}>
          <FormattedMessage id="PasswordRecoveryPage.passwordMessageInstructions" />
        </p>
        <PaswordResetWithCodeForm
          onSubmit={values => resetPasswordWithCodeCognito(values.code, values.newPassword)}
          initialEmail={initialEmail}
        />
      </>
    )
    : (
    <>
    <p className={css.modalMessage}>
      <FormattedMessage id="PasswordRecoveryPage.forgotPasswordMessage" />
    </p>
    <PasswordRecoveryForm
      inProgress={recoveryInProgress}
      onChange={onChange}
      onSubmit={values => recoverPasswordCognito(values.email)}
      recoverPasswordCognito={values => recoverPasswordCognito(values)}
      initialValues={{ email: initialEmail }}
      recoveryError={recoveryError}
      emailSent={emailSent}
    />
    </>
  );

  const submitEmailContent = (
    <div className={css.submitEmailContent}>
      <IconKeys className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="PasswordRecoveryPage.forgotPasswordTitle" />
      </h1>
      {message}

    </div>
  );

  const submittedEmailText = passwordRequested ? (
    <span className={css.email}>{initialEmail}</span>
  ) : (
    <span className={css.email}>{submittedEmail}</span>
  );

  const emailSubmittedContent = (
    <div className={css.emailSubmittedContent}>
      <IconKeys className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="PasswordRecoveryPage.emailSubmittedTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage
          id="PasswordRecoveryPage.emailSubmittedMessage"
          values={{ submittedEmailText }}
        />
      </p>
      <div className={css.bottomWrapper}>
        <p className={css.helperText}>
          {recoveryInProgress ? (
            <FormattedMessage id="PasswordRecoveryPage.resendingEmailInfo" />
          ) : (
            <FormattedMessage
              id="PasswordRecoveryPage.resendEmailInfo"
              values={{ resendEmailLink }}
            />
          )}
        </p>
        <p className={css.helperText}>
          <FormattedMessage id="PasswordRecoveryPage.fixEmailInfo" values={{ fixEmailLink }} />
        </p>
      </div>
    </div>
  );

  const genericErrorContent = (
    <div className={css.genericErrorContent}>
      <IconKeys className={css.modalIcon} />
      <h1 className={css.modalTitle}>
        <FormattedMessage id="PasswordRecoveryPage.actionFailedTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="PasswordRecoveryPage.actionFailedMessage" />
      </p>
    </div>
  );

  let content;
  if (isPasswordRecoveryEmailNotFoundError(recoveryError)) {
    content = submitEmailContent;
  } else if (recoveryError) {
    content = genericErrorContent;
  } else if (submittedEmail || passwordRequested) {
    content = emailSubmittedContent;
  } else {
    content = submitEmailContent;
  }


  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    //console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };



  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      {redirect}
      {/** Success Modal */}
      <Modal
        {...props}
        isOpen={isSuccessOpen}
        onClose={() => {
          setSuccessOpen(false);
          setSuccess(true);
        }}
       onManageDisableScrolling={onManageDisableScrolling}
      >
        <div style={{ margin: '1rem' }}>Password Reset Successful</div>
      </Modal>

      {/** Error Modal */}
      <Modal
        {...props}
        isOpen={isErrorOpen}
        onClose={() => {
          setErrorOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div style={{ margin: '1rem' }}>Error: {errorMessage}</div>
      </Modal>

      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>{content}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

PasswordRecoveryPageComponent.defaultProps = {
  sendVerificationEmailError: null,
  initialEmail: null,
  submittedEmail: null,
  recoveryError: null,
};

const { bool, func, string } = PropTypes;

PasswordRecoveryPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  initialEmail: string,
  submittedEmail: string,
  recoveryError: propTypes.error,
  recoveryInProgress: bool.isRequired,
  passwordRequested: bool.isRequired,
  onChange: func.isRequired,
  onRetypeEmail: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    initialEmail,
    submittedEmail,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
    emailSent,
  } = state.PasswordRecoveryPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    initialEmail,
    submittedEmail,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
    emailSent,
  };
};


const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(clearPasswordRecoveryError()),
  //onSubmitEmail: email => dispatch(recoverPassword(email)),
  //onSubmitEmail: email => recoverPasswordCognito(email),
  //onResetPasswordWithCode: (code) => resetPasswordWithCodeCognito(code),
  onRetypeEmail: () => dispatch(retypePasswordRecoveryEmail()),
});

const PasswordRecoveryPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PasswordRecoveryPageComponent);

export default PasswordRecoveryPage;
