import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { ProfileSettingsForm, ProfileEducatorSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  render() {
    const {
      currentUser,
      currentUserListing,
      image,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      intl,
    } = this.props;

    const isEducator =
      currentUser &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.educator;

    const handleSubmit = values => {
      const {
        firstName,
        lastName,
        bio: rawBio,
        titleSt,
        language,
        timezone,
        pronoun,
        uniDegree,
        gender,
        additionalInfo,
      } = values;

      // Ensure that the optional bio is a string
      const bio = rawBio || '';

      const uploadedImage = this.props.image;
      // const isImageUploaded =
      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio,
        publicData: { titleSt, timezone, pronoun, uniDegree, language, gender, additionalInfo },
      };

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues);
    };

    const handleEducatorSubmit = values => {
      const { firstName, lastName } = values;

      const uploadedImage = this.props.image;
      // const isImageUploaded =
      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      };
      const listingId = currentUserListing ? currentUserListing.id : '';
      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues, listingId);
    };

    const user = ensureCurrentUser(currentUser);
    const { firstName, lastName, bio } = user.attributes.profile;

    let publicDataNew = {};

    if (user.attributes.profile.publicData && !isEducator) {
      const {
        publicData: { titleSt, timezone, language, pronoun, uniDegree, gender, additionalInfo },
      } = user.attributes.profile;

      publicDataNew = {
        titleSt,
        timezone,
        language,
        pronoun,
        uniDegree,
        gender,
        additionalInfo,
      };
    }
    const {
      titleSt,
      timezone,
      pronoun,
      language = [{ language: null }],
      uniDegree = [{ university: null, degree: null }],
      gender,
      additionalInfo,
    } = publicDataNew;
    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };

    const degreeOptions = findOptionsForSelectFilter('st_degree', config.custom.fields);
    const titleOptions = findOptionsForSelectFilter('st_title', config.custom.fields);
    const genderOptions = findOptionsForSelectFilter('st_gender', config.custom.fields);
    const pronounOptions = findOptionsForSelectFilter('st_pronoun', config.custom.fields);
    const languageOptions = findOptionsForSelectFilter('language', config.custom.filters);

    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{
          firstName,
          lastName,
          titleSt,
          timezone,
          uniDegree,
          language,
          gender,
          pronoun,
          bio,
          additionalInfo,
          profileImage: user.profileImage,
        }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        degreeOptions={degreeOptions}
        titleOptions={titleOptions}
        genderOptions={genderOptions}
        pronounOptions={pronounOptions}
        languageOptions={languageOptions}
        onSubmit={handleSubmit}
      />
    ) : null;

    const profileEducatorSettingsForm = user.id ? (
      <ProfileEducatorSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{
          firstName,
          lastName,
          profileImage: user.profileImage,
        }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleEducatorSubmit}
      />
    ) : null;

    const pageTitle = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={pageTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
            <UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </div>
              {isEducator ? profileEducatorSettingsForm : profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListing } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    currentUserListing,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: (data, listingId) => dispatch(updateProfile(data, listingId)),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
