import React from 'react';
import PropTypes from 'prop-types';
import image from '../../assets/Polis_Logo.png';

const IconLogo = props => {
  const { className, format } = props;

  if (format === 'desktop') {
    return <img className={className} src={image} alt="Logo" style={{ width: '100px' }} />;
  }

  return <img className={className} src={image} alt="Logo" style={{ width: '45px' }} />;
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
