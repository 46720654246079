import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, keywords, pronounOptions, titleOptions, publicData } = props;

  const preferedPronoun = publicData && publicData.pronoun ? publicData.pronoun : '';
  const seletedPronoun = pronounOptions.find(s => preferedPronoun === s.key);

  const title = publicData && publicData.titleOcc ? publicData.titleOcc : '';
  const seletedTitle = titleOptions.find(s => title === s.key);

  return (
    <>
      {/*{title ? (*/}
      {/*  <div className={css.sectionDescriptionColumn}>*/}
      {/*    <h2 className={css.descriptionTitle}>*/}
      {/*      <FormattedMessage id="ListingPage.title" />*/}
      {/*    </h2>*/}
      {/*    <p className={css.description}>*/}
      {/*      {richText(seletedTitle.label, {*/}
      {/*        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,*/}
      {/*        longWordClass: css.longWord,*/}
      {/*      })}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {/*{preferedPronoun ? (*/}
      {/*  <div className={css.sectionDescriptionColumn}>*/}
      {/*    <h2 className={css.descriptionTitle}>*/}
      {/*      <FormattedMessage id="ListingPage.pronounTitle" />*/}
      {/*    </h2>*/}
      {/*    <p className={css.description}>*/}
      {/*      {richText(seletedPronoun.label, {*/}
      {/*        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,*/}
      {/*        longWordClass: css.longWord,*/}
      {/*      })}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {description ? (
        <div className={css.sectionDescription}>
          <h2 className={css.descriptionTitle}>
            <FormattedMessage id="ListingPage.descriptionTitle" />
          </h2>
          <p className={css.description}>
            {richText(description, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      ) : null}


      {/*{keywords ? (*/}
      {/*  <div className={css.sectionDescription}>*/}
      {/*    <h2 className={css.descriptionTitle}>*/}
      {/*      <FormattedMessage id="ListingPage.keywordsTitle" />*/}
      {/*    </h2>*/}
      {/*    <p className={css.description}>*/}
      {/*      {richText(keywords, {*/}
      {/*        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,*/}
      {/*        longWordClass: css.longWord,*/}
      {/*      })}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {/* {language ? (
        <div className={css.sectionDescription}>
          <h2 className={css.descriptionTitle}>
            <FormattedMessage id="ListingPage.descriptionTitle" />
          </h2>
          <p className={css.description}>
            {richText(description, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      ) : null} */}
    </>
  );
};

export default SectionDescriptionMaybe;
