import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import {
  InlineTextButton,
  ExternalLink
} from '../../components';
import css from './SearchFiltersSecondary.css';

class SearchFiltersSecondaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentQueryParams: props.urlQueryParams };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { applyFilters, onClosePanel } = this.props;

    if (applyFilters) {
      applyFilters();
    }

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { cancelFilters } = this.props;

    if (cancelFilters) {
      cancelFilters();
    }

    this.props.onClosePanel();
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { resetAll, onClosePanel } = this.props;

    if (resetAll) {
      resetAll(e);
    }

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const { rootClassName, className, children, resultsCount } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <h2 className={css.title}>
          {(  ( resultsCount === 0) && (!this.props.isLimitedSearch) ) ? (
            /*<FormattedMessage id="SearchPage.noResults" /> */
            <div>
              <div className={css.title}>Hmm, your search has returned no results. Let’s fix that ASAP!</div>


              <div className={css.title}>Tips to optimize your Personal Educator search: </div>
            
                <ul className={css.bulletList}>      
                  <li>Check your spelling </li>
                  <li>Use different or fewer keywords </li>
                  <li>Start with something more general – you can always narrow your results later </li>
                  <li>Use different or fewer filters </li>
                  <li>Check and adjust the Availability filter </li>
                  <li>Consult our Keyword Guide for optimizing your search results (and profile)</li>
                </ul>
             
              
              <div className={css.title}>Still Need Assistance?</div>
              
              
              <ExternalLink className={css.title} href="https://polis.ac/contact-us">Contact Us</ExternalLink>
            
            </div>
          ) : (
            <div>
              <div className={css.title}><FormattedMessage id="SearchPage.title1" /></div>
              <div className={css.title}><FormattedMessage id="SearchPage.title2" /></div>
              <div className={css.title}><FormattedMessage id="SearchPage.title3" /></div>
            </div>

          )}
        </h2>
        <div className={css.filtersWrapperFirst}>{children[0]}</div>
        <div className={css.filtersWrapper}>
          <>{children[1]}</>
          <>{children[2]}</>
          <>{children[3]}</>
          </div>

        <div className={css.footer}>
          <InlineTextButton rootClassName={css.resetAllButton} onClick={this.resetAll}>
            <FormattedMessage id={'SearchFiltersSecondary.resetAll'} />
          </InlineTextButton>
          <InlineTextButton rootClassName={css.cancelButton} onClick={this.cancelFilters}>
            <FormattedMessage id={'SearchFiltersSecondary.cancel'} />
          </InlineTextButton>
          <InlineTextButton rootClassName={css.applyButton} onClick={this.applyFilters}>
            <FormattedMessage id={'SearchFiltersSecondary.apply'} />
          </InlineTextButton>
        </div>
      </div>
    );
  }
}

SearchFiltersSecondaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SearchFiltersSecondaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  applyFilters: func.isRequired,
  resetAll: func.isRequired,
  onClosePanel: func.isRequired,
};

const SearchFiltersSecondary = SearchFiltersSecondaryComponent;

export default SearchFiltersSecondary;
