import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SectionLogin.css';

const SectionTestimonials = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const signupButton = (
    <NamedLink name="SignupPage" className={css.signupButton}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginButton = (
    <NamedLink name="LoginPage" className={css.loginButton}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLogin.title" />
      </div>

      <div className={css.buttons}>
        {/*{signupButton}*/}
        {loginButton}
      </div>
    </div>
  );
};

SectionTestimonials.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionTestimonials.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTestimonials;
