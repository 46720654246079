import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  Reviews,
  ButtonTabNavHorizontal,
  PropertyGroup,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { loadData } from './ProfilePage.duck';
import config from '../../config';

import css from './ProfilePage.css';
import { findOptionsForSelectFilter } from '../../util/search';

const { UUID } = sdkTypes;
const MAX_MOBILE_SCREEN_WIDTH = 768;

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      reviews,
      queryReviewsError,
      viewport,
      intl,
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const displayName = profileUser.attributes.profile.displayName;
    const bio = profileUser.attributes.profile.bio;
    const hasBio = !!bio;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const isEducatorProfile =
      profileUser &&
      profileUser.attributes.profile.protectedData &&
      profileUser.attributes.profile.protectedData.educator;

    let options = {
      language: '',
      uniDegree: '',
      title: '',
      pronoun: '',
      additionalInfo: '',
    };

    if (profileUser.attributes.profile.publicData) {
      options = {
        pronoun: profileUser.attributes.profile.publicData.pronoun,
        title: profileUser.attributes.profile.publicData.titleSt,
        language: profileUser.attributes.profile.publicData.language,
        uniDegree: profileUser.attributes.profile.publicData.uniDegree,
        additionalInfo: profileUser.attributes.profile.publicData.additionalInfo,
      };
    }

    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;

    const asideContent = (
      <div className={css.asideContent}>
        <AvatarLarge className={css.avatar} user={user} disableProfileLink />
        <h1 className={css.mobileHeading}>
          {displayName ? (
            <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
          ) : null}
        </h1>
        {editLinkMobile}
        {editLinkDesktop}
      </div>
    );

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    const mobileReviews = (
      <div className={css.mobileReviews}>
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfProviderTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfProvider} />
        {/* <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfCustomer} /> */}
      </div>
    );

    const desktopReviewTabs = [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      // {
      //   text: (
      //     <h3 className={css.desktopReviewsTitle}>
      //       <FormattedMessage
      //         id="ProfilePage.reviewsOfCustomerTitle"
      //         values={{ count: reviewsOfCustomer.length }}
      //       />
      //     </h3>
      //   ),
      //   selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
      //   onClick: this.showOfCustomerReviews,
      // },
    ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          <Reviews reviews={reviewsOfProvider} />
        ) : (
          <Reviews reviews={reviewsOfCustomer} />
        )}
      </div>
    );

    const degreeOptions = findOptionsForSelectFilter('st_degree', config.custom.fields);
    const pronounOptions = findOptionsForSelectFilter('st_pronoun', config.custom.fields);
    const titleOptions = findOptionsForSelectFilter('st_title', config.custom.fields);
    const languageOptions = findOptionsForSelectFilter('language', config.custom.filters);

    const getLanguagesArray = data => data.map(item => item.language);
    const getDegreeValue = degree => {
      const { label } = degreeOptions.find(op => op.key === degree);
      return label;
    };

    const getPronounValue = degree => {
      const { label } = pronounOptions.find(op => op.key === degree);
      return label;
    };
    const getTitleValue = title => {
      const { label } = titleOptions.find(op => op.key === title);
      return label;
    };

    const selectedLanguages = options.language ? getLanguagesArray(options.language) : [];
    const selectedConfigOptions = languageOptions.filter(o =>
      selectedLanguages.find(s => s === o.key)
    );

    const bioLabel = intl.formatMessage({
      id: 'ProfileSettingsForm.bioLabel',
    });    

    const languageLabel = intl.formatMessage({
      id: 'EditListingProfessionalSkillsForm.languageLabel',
    });

    const additionalInfoLabel = intl.formatMessage({
      id: 'ProfileSettingsForm.additionalInfoLabel',
    });


    const mainContent = (
      <>
        <h1 className={css.desktopHeading}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
        </h1>
        <div className={css.customWrapper}>
          <div className={css.customContainer}>
            {options.uniDegree ? (
              <div className={css.containerItem1}>
                <p className={css.title}>Education</p>
                {options.uniDegree.map(item => (
                  <p className={css.bio}>{`${item.university} - ${getDegreeValue(item.degree)}`}</p>
                ))}
              </div>
            ) : null}
            {options.language ? (
              <div className={css.containerItem}>
                <p className={css.title}>{languageLabel}</p>
                <PropertyGroup
                  id="ListingPage.yogaStyles"
                  options={selectedConfigOptions}
                  selectedOptions={selectedLanguages}
                  twoColumns={selectedConfigOptions.length > 5}
                />
              </div>
            ) : null}
            {hasBio ? (
              <>
                <p className={css.title}>{bioLabel}</p>
                <p className={css.bio}>{bio}</p>
              </>
            ) : null}            
            {options.additionalInfo ? (
              <>
                <p className={css.title}>{additionalInfoLabel}</p>
                <p className={css.bio}>{options.additionalInfo}</p>
              </>
            ) : null}

          </div>
          <div className={css.customContainer2}>
            {options.pronoun ? (
              <div className={css.containerItem1}>
                <p className={css.title}>Preferred pronoun</p>
                <p className={css.bio}>{getPronounValue(options.pronoun)}</p>
              </div>
            ) : null}
            {options.title ? (
              <div className={css.containerItem}>
                <p className={css.title}>Title</p>
                <p className={css.bio}>{getTitleValue(options.title)}</p>
              </div>
            ) : null}
          </div>
        </div>
        {isEducatorProfile ? (isMobileLayout ? mobileReviews : desktopReviews) : null}
      </>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    } else if (userShowError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperSideNav className={css.aside}>{asideContent}</LayoutWrapperSideNav>
          <LayoutWrapperMain>{content}</LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { userId, userShowError, reviews, queryReviewsError } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    reviews,
    queryReviewsError,
  };
};

const ProfilePage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
